<template>
    <div class="secondary-sidebar pipeline-menu">
        <div class="pipeline-hamburger" :class="{'hamburger-button-active' : showMenu}" @click="showMenu = !showMenu">
            <div class="hamburger-toggler"></div>
        </div>
        <ul class="secondary-sidebar-body nav flex-column text-break" :class="{'hamburger-menu-show': showMenu}">
            <li v-for="pipeline in pageState.pipelines" :key="pipeline.id">
                <a href="#" class="pipeline-button" @dragover.prevent="dragOver" @dragenter.prevent="dragEnter" @drop="drop"
                @dragleave.prevent="dragLeave" @click.prevent="changePipeline(pipeline)" :pipeline-id="pipeline.id"
                :class="{ 'active' : pageState.activeStage.id === pipeline.id, 'rejected' : pipeline.id === 'rejected', 'selected' : pipeline.id === 'selected' }">
                    <span>{{ $t(pipeline.name) }}</span><span class="candidates-number">{{ pipeline.applicants.length }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import client from "../../../app/api/Client";
import { mapState, mapActions } from 'vuex';
import {UPDATE_PAGE_STATE} from '../../../constants/action-type';
import {DEFAULT_PIPELINE, PERMISSIONS} from '../../../constants/enums';


export default {
    name: "PipelineDefaultSidebar",
    data() {
      return {
          showMenu: false,
      }
    },
    computed: {
        ...mapState(['company', 'pageState']),
    },
    methods: {
        ...mapActions([UPDATE_PAGE_STATE]),
        dragLeave(e) {
            this.removeHighlightedSidebarItemBorder(e);
        },

        dragEnter(e) {
            e.stopPropagation();
            this.highlightSidebarItemBorder(e);
        },

        dragOver(e) {
        },

        async drop(evt) {

            // :Todo move permission related things from a central point.
            if (!this.company.permission[PERMISSIONS.CANDIDATE_ORGANIZE]) {
                this.$toast.error(this.$t(`Unauthorized access`));
                return;
            }

            let applicantId = this.pageState.dragApplicantId;
            let currentPipelineId = this.pageState.dragPipelineId;
            let movePipelineId = evt.target.getAttribute('pipeline-id');

            if (!movePipelineId || !applicantId || currentPipelineId === movePipelineId) {
                return;
            }

            let moveToPipeline = _.find(this.pageState.pipelines, function(pipeline) {
                return pipeline.id == movePipelineId
            });

            if (movePipelineId === DEFAULT_PIPELINE.SELECTED) {
                this.UPDATE_PAGE_STATE({'showOnBoardingModal':true, 'selectedApplicant': [applicantId], 'moveToPipeline': moveToPipeline});
            } else if (movePipelineId == DEFAULT_PIPELINE.REMOTE_INTERVIEW) {
                this.UPDATE_PAGE_STATE({'showRemoteInterviewModal':true, 'selectedApplicant': [applicantId], 'moveToPipeline': moveToPipeline});
            } else {
                this.isLoading = true;
                try {
                    this.applicantOrderUpdateAfterDrop(applicantId, currentPipelineId, movePipelineId);

                    await client().post(`/job/${this.$route.params.jobId}/pipeline/candidate`, {
                        applicants: [applicantId],
                        pipeline: movePipelineId,
                        last_applicant: true
                    });
                } catch (error) {
                    this.$toast.error(this.$t(error.response.data.message));
                    if (error.response.status === 480 || error.response.status === 490) {
                        await this.$router.push({name: 'jobs.published'});
                    }
                }
                this.isLoading = false;
            }
        },

        applicantOrderUpdateAfterDrop(applicantId, currentPipelineId, movePipelineId) {
            let dropCandidate  = _.find(this.pageState.activeStage.applicants, candidate => candidate.id == applicantId);
            let dropAfterCandidates = _.filter(this.pageState.activeStage.applicants, candidate => candidate.id != applicantId);

            this.pageState.pipelines.map((pipeline) => {
                if(pipeline.id == currentPipelineId) {
                    pipeline.applicants = dropAfterCandidates;
                }
                if(pipeline.id == movePipelineId) {
                    pipeline.applicants.push(dropCandidate);
                }
                return pipeline;
            });

            this.UPDATE_PAGE_STATE({'dropCandidate':false});
        },

        highlightSidebarItemBorder(e) {
            let target = e.target;
            if (target.tagName === 'SPAN' || target.tagName === 'A') {
                target = target.parentNode;
            }
            if (!target.classList.contains('active')) {
                target.classList.add('dropable');
            }
        },

        removeHighlightedSidebarItemBorder(e) {
            e.target.parentNode.classList.remove('dropable');
        },

        changePipeline(pipeline = null) {
            if (pipeline === null) {
                pipeline = _.first(this.pageState.pipelines);
            } else {
                pipeline = _.find(this.pageState.pipelines, p => p.id === pipeline.id);
            }

            pipeline.applicants.map((a) => {
                a.isSelected = false;
                return a;
            });

            this.showMenu = false;

            this.UPDATE_PAGE_STATE({'activeStage': pipeline, 'selectedApplicant': [], 'dropCandidate': true});

            this.$router.push({name: 'job.pipeline', params: {jobId: this.$route.params.jobId}, query: {pipeline: pipeline.id}});
        },
    },
    created() {
        let pipeline = null;
        if (this.$route.query.pipeline) {
            pipeline = _.find(this.pageState.pipelines, p => p.id == this.$route.query.pipeline);
        }
        this.changePipeline(pipeline);
    }
}
</script>

<style scoped>

    .pipeline-nav li {
        border-radius: 5px
    }
    .pipeline-nav li.dropable {
        box-shadow: 0 0 10px rgba(88, 125, 252, .3);
        transform: scale(1.03);
        box-sizing: border-box;
    }
    .pipeline-button span {
        pointer-events: none;
    }
    .dropable {
        border: 1px solid #597dfc80;
    }

    @media (max-width: 1399.98px) {
        .page-body .content-area__body .pipeline-section .pipeline-box .pipeline-menu {
            width: auto !important;
        }
    }
</style>
